import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import * as cx from './Intro.module.scss'

const Intro = ({
  wrapperClassName,
  heading,
  subheading,
  paragraphs,
  features,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-7',
  paragraphClassName = 'subtitle-5',
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={cx.container}>
        <h3 className={cx.header}>
          <span className={headingClassName}>{parse(heading)}</span>
          <span className={subheadingClassName}>{subheading}</span>
        </h3>
        <hr />
        {paragraphs.map((paragraph, key) => {
          return (
            <p key={key} className={paragraphClassName}>
              {paragraph}
            </p>
          )
        })}

        <div className={cx.features}>
          {features.map((feature, key) => {
            return (
              <div key={key} className={cx.feature}>
                <span className={cx.icon}>{feature.icon}</span>
                <span className={paragraphClassName}>
                  {feature.description}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

Intro.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraphs: PropTypes.array,
  features: PropTypes.array,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Intro
