import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import PartsHeader from '@parts/header'
import PartsFooter from '@parts/footer'
import FloatingShareButtons from '@components/global/element/FloatingShareButtons'
import useWindowScroll from '@hooks/useWindowScroll'

import SvgLogoMono from '@svg/mono.inline'

const LayoutInnerPage = ({
  wrapperClassName,
  buttonsHeight,
  children,
  breadcrumbs,
  hasTransparentNav,
  hasWhiteTransparentNav,
  hasBlackTransparentNav,
  hasWhiteTextTransparentNav,
  noFloatingShareButtons,
  hasFloatingSvgMono,
  showFooterNav = true,
  showFooterBg = true,
}) => {
  const navVariants =
    hasTransparentNav ||
    hasWhiteTransparentNav ||
    hasBlackTransparentNav ||
    hasWhiteTextTransparentNav

  return (
    <div
      className={clsx(wrapperClassName, 'inner-page-wrapper', {
        'transparent-nav': navVariants,
        'transparent-nav--white': hasWhiteTransparentNav,
        'transparent-nav--black': hasBlackTransparentNav,
        'transparent-nav--white-text': hasWhiteTextTransparentNav,
        'transparent-nav__scroll': useWindowScroll() && navVariants,
        'ip-relative': hasFloatingSvgMono,
      })}
    >
      {hasFloatingSvgMono && <SvgLogoMono className="svg-logo-mono-floating" />}
      <PartsHeader />
      {!noFloatingShareButtons && (
        <FloatingShareButtons height={buttonsHeight} />
      )}
      <main>{children}</main>
      <PartsFooter showNav={showFooterNav} showBg={showFooterBg} />
    </div>
  )
}

LayoutInnerPage.propTypes = {
  wrapperClassName: PropTypes.string,
  hasTransparentNav: PropTypes.bool,
  hasWhiteTransparentNav: PropTypes.bool,
  hasBlackTransparentNav: PropTypes.bool,
  hasWhiteTextTransparentNav: PropTypes.bool,
  noFloatingShareButtons: PropTypes.bool,
  hasFloatingSvgMono: PropTypes.bool,
  buttonsHeight: PropTypes.object,
  children: PropTypes.node,
  breadcrumbs: PropTypes.array,
  showFooterNav: PropTypes.bool,
  showFooterBg: PropTypes.bool,
}

export default LayoutInnerPage
