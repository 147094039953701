import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import {
  Banner,
  Intro,
  Features,
  Packages,
  Compare,
  Premier,
} from '@components/pages/seo-packages'
import Faqs from '@components/global/accordions/Faqs'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import Tabs from '@components/global/tabs/DigitalMarketing'
import {
  pageData as page,
  bannerData,
  tabsData,
  introData,
  featuresData,
  packagesData,
  compareData,
  premierData,
  faqsData,
  reviewsData,
} from '@src/data/SeoPackages'
import useSeoPackagesMetadata from '@hooks/seo-packages-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './SeoPackages.module.scss'

const SeoPackages = () => {
  const {
    bannerImg,
    bannerBG,
    bannerBGTablet,
    bannerBGPhone,
    featuresImg,
    packagesFoundation,
    packagesLocal,
    packagesTotal,
    compareBGDesktop,
    compareBG,
    premierImg,
    faqsBGDesktop,
    faqsBG,
    reviewsBG,
    lcfBG,
  } = useSeoPackagesMetadata()
  const packagesImages = [packagesFoundation, packagesLocal, packagesTotal]
  const isDesktopLarge = useWindowSize().width >= 1280
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        heading={bannerData.heading}
        subheading={bannerData.subheading}
        buttonText={bannerData.button}
        buttonScrollTo={bannerData.buttonScrollTo}
        alt={bannerData.alt}
        bg={extractImage(
          isDesktop ? bannerBG : isPhone ? bannerBGPhone : bannerBGTablet
        )}
        img={extractImage(bannerImg)}
      />
      <Tabs
        tabsClassName={tabsData.tabsClassName}
        activeTab={tabsData.activeTab}
      />
      <Intro
        heading={introData.heading}
        subheading={introData.subheading}
        paragraphs={introData.paragraphs}
        features={introData.features}
      />
      <Features
        heading={featuresData.heading}
        subheading={featuresData.subheading}
        paragraphs={featuresData.paragraphs}
        features={featuresData.features}
        img={extractImage(featuresImg)}
      />
      <Packages
        heading={
          isDesktop ? packagesData.heading.desktop : packagesData.heading.mobile
        }
        subheading={
          isDesktop
            ? packagesData.subheading.desktop
            : packagesData.subheading.mobile
        }
        items={packagesData.items}
        imgItems={packagesImages}
        buttonText={packagesData.buttonText}
      />
      <Compare
        heading={compareData.heading}
        subheading={compareData.subheading}
        buttonText={compareData.buttonText}
        items={compareData.items}
        bg={extractImage(isDesktopLarge ? compareBGDesktop : compareBG)}
      />
      <Premier
        heading={premierData.heading}
        subheading={premierData.subheading}
        title={premierData.title}
        description={premierData.description}
        best={premierData.best}
        duration={premierData.duration}
        buttonText={premierData.buttonText}
        features={premierData.features}
        img={extractImage(premierImg)}
      />
      <Faqs
        noZoomEffect
        wrapperClassName={cx.faqs}
        bgImg={extractImage(isDesktopLarge ? faqsBGDesktop : faqsBG)}
        items={faqsData}
      />
      <ReviewsServiceSetsUsApart
        backgroundUrl={extractImage(reviewsBG)}
        title={reviewsData.title.text}
        titleClassName={reviewsData.title.className}
        subtitle={reviewsData.subtitle.text}
        subtitleClassName={reviewsData.subtitle.className}
        description={reviewsData.description.text}
        descriptionClassName={reviewsData.description.className}
        layout={reviewsData.layout}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        background={lcfBG}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default SeoPackages
