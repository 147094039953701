import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import CheckItems from '@components/global/CheckItems'
import * as cx from './Features.module.scss'

const Features = ({
  wrapperClassName,
  heading,
  subheading,
  paragraphs,
  features,
  img,
  headingClassName = 'heading-3',
  subheadingClassName = 'subtitle-7',
  bodyClassName = 'subtitle-5',
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={cx.container}>
        <div className={cx.image}>
          <ElementImage src={img} alt={heading} />
        </div>

        <div className={cx.content}>
          <h4 className={cx.header}>
            <span className={headingClassName}>{heading}</span>
            <span className={subheadingClassName}>{subheading}</span>
          </h4>

          {paragraphs.map((paragraph, key) => {
            return (
              <p key={key} className={bodyClassName}>
                {paragraph}
              </p>
            )
          })}

          <div className={cx.features}>
            <CheckItems items={features} itemClass={bodyClassName} />
          </div>
        </div>
      </div>
      <hr />
    </section>
  )
}

Features.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraphs: PropTypes.array,
  features: PropTypes.array,
  img: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
}

export default Features
