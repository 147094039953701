import React, { useState, useEffect } from 'react'
import { debounce } from '@utils/debounce'

const useWindowScroll = (position = 80) => {
  const [isScroll, setIsScroll] = useState(false)

  useEffect(() => {
    const detectScrollPositionY = debounce(() => {
      setIsScroll(window.scrollY > position)
    }, 50)

    detectScrollPositionY()

    window.addEventListener('scroll', detectScrollPositionY)

    return () => {
      window.removeEventListener('scroll', detectScrollPositionY)
    }
  })

  return isScroll
}

export default useWindowScroll
