import React from 'react'
import SvgVisibility from '@svg/visibility.inline'
import SvgBrandPresence from '@svg/brandPresence.inline'
import SvgReporting from '@svg/reporting.inline'

export const pageData = {
  title: 'Real Estate SEO for Agents',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Digital Marketing',
      url: '/real-estate-digital-marketing/',
    },
    {
      text: 'Real Estate SEO for Agents',
      url: '/real-estate-digital-marketing/seo-packages/',
    },
  ],
  uri: '/real-estate-digital-marketing/seo-packages/',
  lcf: {
    version: 'LCF04',
    model: 'NicoAlt',
    title: 'Book a FREE Consultation',
    subtitle: 'WANT MORE INFO ON OUR SEO PACKAGES?',
    submitLabel: 'let’s talk',
    leadName: 'Consultation: Real Estate SEO for Agents',
    layout: 'B',
  },
}

export const bannerData = {
  heading: 'Real Estate SEO for Agents',
  subheading: 'Rank up your business by boosting your search ranking.',
  button: 'View our<br/> SEO Packages',
  buttonScrollTo: '#seo-packages',
  alt: 'The Altman Brothers SEO screenshots',
}

export const tabsData = {
  activeTab: 0,
  tabsClassName: 'small-heading',
}

export const introData = {
  heading: 'Stand Out With<br/> Search Engine Optimization',
  subheading: 'GAIN THE LION’S SHARE OF CLICKS & LEADS',
  paragraphs: [
    'You’ve got a lot of online competition, but you can get ahead by being one of the top-ranked search results. After all, the top three Google search results get 54.4% of all clicks. ',
    'That’s the power of SEO, a set of strategies designed to elevate your search visibility. Not only does it make you easier to find online, but you also enjoy the added credibility that comes with being a top Google result.',
  ],
  features: [
    {
      icon: <SvgVisibility />,
      description: 'Increase visibility and get more leads',
    },
    {
      icon: <SvgBrandPresence />,
      description: 'Enhance your credibility and brand authority',
    },
    {
      icon: <SvgReporting />,
      description: 'Data-driven reporting gives you a clear idea of your ROI',
    },
  ],
}

export const featuresData = {
  heading: 'Why do you need SEO?',
  subheading: 'HOW CAN SEO HELP YOU?',
  paragraphs: [
    '47% of buyers in 2022 started their home search online, according to the National Association of REALTORS®. This means without a strong online presence, you’re losing out on almost half of all your prospective clients.',
    'With the right SEO strategy, you can put yourself in front of the right people at the most crucial time: the moment they hit “search.”',
  ],
  features: [
    {
      content: 'Improve Online Visibility',
      check: true,
    },
    {
      content: 'Target Niche Market',
      check: true,
    },
    {
      content: 'Build Trust and Authority',
      check: true,
    },
    {
      content: 'Establish Credibility',
      check: true,
    },
    {
      content: 'Boost Brand Awareness',
      check: true,
    },
    {
      content: 'Generate Leads',
      check: true,
    },
    {
      content: 'Increase Relevant Site Traffic',
      check: true,
    },
    {
      content: 'Drive Conversions',
      check: true,
    },
    {
      content: 'Gain Market Insight',
      check: true,
    },
    {
      content: 'Grow Online Sales',
      check: true,
    },
  ],
}

export const packagesData = {
  heading: { desktop: 'Our SEO Packages', mobile: 'What We Can Do' },
  subheading: {
    desktop: 'START SMALL OR GO BIG? WE’VE GOT A PLAN FOR YOU.',
    mobile: 'Choose the seo package best suited for your needs',
  },
  buttonText: 'GET PRICING & FEATURES',
  items: [
    {
      title: 'SEO Foundation',
      description:
        'If online visibility is a marathon, SEO Foundation is signing up for the race. Get on the fast track to success with search-optimized pages designed to attract web traffic and leads.',
      best:
        'Agents who are setting up a new website or want to make their current one more search-friendly.',
      duration: '1 Month',
    },
    {
      title: 'SEO Local',
      description:
        'This package allows you to close the gap among competitors in your local market. With this plan, your website is optimized for local searches, helping you become the go-to expert in your community.',
      best:
        'Agents who want hyper-targeted SEO focused on key markets they work in.',
      duration: '1 month',
    },
    {
      title: 'SEO Total',
      description:
        'Go the distance with a package that covers all your bases, including targeted keywords and search-optimized longform blogs.',
      best:
        'Agents who want aggressive SEO strategies to quickly build up their online dominance.',
      duration: '1 month setup + 6 months optimization',
    },
  ],
}

export const compareData = {
  heading: 'Compare Packages',
  subheading: 'All Our SEO Packages at a Glance',
  buttonText: 'Get this package',
  items: [
    {
      title: 'SEO Foundation',
      duration: '1 month duration',
      features: {
        desktop: [
          {
            content: '5 Pages',
            check: true,
          },
          {
            content: '5 Keywords',
            check: true,
          },
          {
            content: 'Website Audit',
            check: true,
          },
          {
            content: 'Competitor Analysis',
            check: true,
          },
          {
            content: 'Keyword Research and Mapping',
            check: true,
          },
          {
            content: 'On-Page Optimization',
            check: true,
          },
          {
            content: 'Site Indexing and Improvements',
            check: true,
          },
          {
            content: 'Benchmark Report',
            check: true,
          },
        ],
      },
    },
    {
      title: 'SEO Local',
      duration: '1 month duration',
      features: {
        desktop: [
          {
            content: '10 Pages',
            check: true,
          },
          {
            content: '10 Keywords',
            check: true,
          },
          {
            content: 'Website Audit',
            check: true,
          },
          {
            content: 'Competitor Analysis',
            check: true,
          },
          {
            content: 'Keyword Research and Mapping',
            check: true,
          },
          {
            content: 'On-Page Optimization',
            check: true,
          },
          {
            content: 'Site Indexing and Improvements',
            check: true,
          },
          {
            content: 'Benchmark Report',
            check: true,
          },
          {
            content:
              'Local Search Setup<br/> (GOOGLE MY BUSINESS, YELP, YAHOO YEXT BUSINESS LISTING, BING PLACES)',
            check: true,
          },
        ],
        phone: [
          {
            content: '10 Pages',
            check: true,
          },
          {
            content: '10 Keywords',
            check: true,
          },
          {
            content: 'Website Audit',
            check: true,
          },
          {
            content: 'Competitor Analysis',
            check: true,
          },
          {
            content: 'Keyword Research and Mapping',
            check: true,
          },
          {
            content: 'On-Page Optimization',
            check: true,
          },
          {
            content: 'Site Indexing and Improvements',
            check: true,
          },
          {
            content: 'Benchmark Report',
            check: true,
          },
          {
            content:
              'Local Search Setup<br/> (GOOGLE MY BUSINESS, YELP, YEXT, BING PLACES)',
            check: true,
          },
        ],
      },
    },
    {
      title: 'SEO Total',
      duration: '1 month setup<br/> + 6 months optimization',
      features: {
        desktop: [
          {
            content: '10 Pages',
            check: true,
          },
          {
            content: '10 Keywords',
            check: true,
          },
          {
            content: 'Website Audit',
            check: true,
          },
          {
            content: 'Competitor Analysis',
            check: true,
          },
          {
            content: 'Keyword Research and Mapping',
            check: true,
          },
          {
            content: 'On-Page Optimization',
            check: true,
          },
          {
            content: 'Site Indexing and Improvements',
            check: true,
          },
          {
            content:
              'Local Search Setup<br/> (GOOGLE MY BUSINESS, YELP, YAHOO YEXT BUSINESS LISTING, BING PLACES)',
            check: true,
          },
          {
            content: 'Benchmark Report',
            check: true,
          },
          {
            content: 'Monthly Long-Form<br/> Blog Content (2,500 words)',
            check: true,
          },
          {
            content: 'Monthly Performance Reports',
            check: true,
          },
        ],
        phone: [
          {
            content: '10 Pages',
            check: true,
          },
          {
            content: '10 Keywords',
            check: true,
          },
          {
            content: 'Website Audit',
            check: true,
          },
          {
            content: 'Competitor Analysis',
            check: true,
          },
          {
            content: 'Keyword Research and Mapping',
            check: true,
          },
          {
            content: 'On-Page Optimization',
            check: true,
          },
          {
            content: 'Site Indexing and Improvements',
            check: true,
          },
          {
            content:
              'Local Search Setup<br/> (GOOGLE MY BUSINESS, YELP, YEXT, BING PLACES)',
            check: true,
          },
          {
            content: 'Benchmark Report',
            check: true,
          },
          {
            content: 'Monthly Long-Form<br/> Blog Content (2,500 words)',
            check: true,
          },
          {
            content: 'Monthly Performance Reports',
            check: true,
          },
        ],
      },
    },
  ],
}

export const premierData = {
  heading: 'Looking for<br/> Super-Powered SEO?',
  subheading: 'Here’s our most<br/> comprehensive SEO Package',
  title: 'SEO Premier',
  description:
    'Pull out all the stops with an SEO package that gets you to the podium — and keeps you there.',
  best:
    'Agents who require both on-page and off-page SEO to maximize their search visibility and web traffic.',
  duration: '1 Month Setup + 6 or 12 Months Optimization',
  buttonText: 'GET PRICING & FEATURES',
  features: [
    {
      content: 'Website Audit',
      check: true,
    },
    {
      content: 'Competitor Analysis',
      check: true,
    },
    {
      content: 'Keyword Research and Mapping',
      check: true,
    },
    {
      content: 'On-Page Optimization',
      check: true,
    },
    {
      content: 'Site Indexing and Improvements',
      check: true,
    },
    {
      content:
        '<ul>Local Search Setup:<li> Google My Business</li><li> Yahoo Yext Business Listing</li><li> Yelp</li><li> Bing Places</ul>',
      check: true,
    },
    {
      content: 'Benchmark Report',
      check: true,
    },
  ],
}

export const faqsData = [
  {
    question: 'What is SEO?',
    answer: [
      'Search engine optimization is a marketing technique whose goal is to boost your Google search ranking. We achieve this through:',
      '-Optimizing site loading speed<br/>-Incorporating keywords into your website copy<br/>-Adding alt-text descriptions with keywords to images<br/>-Creating meta titles and descriptions<br/>-Internal linking to other pages on your site',
    ],
  },
  {
    question: 'Why do you need SEO?',
    answer: [
      "Real estate clients typically start by looking for agents online with the help of search engines like Google. If you're not one of the top search results, they're unlikely to connect with you – and you lose out on potential business. SEO ensures you have strong search visibility, which offers two key benefits: you're easier to find online and your high ranking lends your business more credibility.",
    ],
  },
  {
    question:
      'What is the difference between Real Estate Agent SEO and Real Estate Broker SEO?',
    answer: [
      'A broker may work independently, or start their own brokerage and employ real estate agents. Real estate agents and brokers have different roles in the industry, but they both face similar challenges when it comes to search engine optimization for their business needs.',
    ],
  },
  {
    question: 'If I do SEO once, will I need to do it again?',
    answer: [
      'While SEO is a long-term marketing tactic, you need to recalibrate your website and strategy every so often. For one, search engines like Google often tweak their algorithms, which determine which sites or pages are shown in search results. For another, you may have added pages to your website that have yet to be search optimized. Keeping your SEO strategy up to date gives you the best chance for success.',
    ],
  },
]

export const reviewsData = {
  title: { text: 'Our Success Stories', className: 'heading-5' },
  subtitle: {
    text: 'Amazing customer experiences',
    className: 'subtitle-7',
  },
  description: {
    text:
      'Satisfaction comes standard when you work with Agent Image. But don’t take our word for it — we’ll let our elite clients do the talking!',
    className: 'subtitle-5',
  },
  layout: 'B',
}
