import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Button } from '@components/global/buttons/Buttons'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import ElementImage from '@components/global/element/Image'
import SvgCalendar from '@svg/calendar.inline'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Packages.module.scss'

const PackageCard = ({
  wrapperClassName,
  title,
  description,
  best,
  duration,
  buttonText,
  img,
  label1 = 'Best For',
  label2 = 'Project Duration',
  titleClassName = 'heading-3',
  labelClassName = 'subtitle-1',
  bodyClassName = 'subtitle-5',
  ctaOnClick,
}) => {
  return (
    <li className={cx.item}>
      <div className={cx.content}>
        <h5 className={titleClassName}>{title}</h5>
        <p className={bodyClassName}>{description}</p>
        <span className={labelClassName}>{label1}</span>
        <p className={bodyClassName}>{best}</p>
        <span className={labelClassName}>{label2}</span>
        <div className={cx.duration}>
          <span className={cx.icon}>
            <SvgCalendar />
          </span>
          <span className={bodyClassName}>{duration}</span>
        </div>
        <Button text={buttonText} onClick={ctaOnClick} />
      </div>
      <div className={cx.image}>
        <ElementImage src={extractImage(img)} alt={title} />
      </div>
    </li>
  )
}

const Packages = ({
  wrapperClassName,
  heading,
  subheading,
  items,
  imgItems,
  buttonText,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-7',
  modalTitle = 'Sign Up Today For A <br>Free Consultation',
  modalSubmit = 'Get Started Today',
  modalTemplate = 'm1',
  modalType = 'b',
}) => {
  const modalRef = useRef()

  return (
    <section id="seo-packages" className={clsx(wrapperClassName, [cx.wrapper])}>
      <h2 className={cx.header}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h2>
      <ul className={cx.list}>
        {items?.map((item, key) => {
          return (
            <PackageCard
              key={key}
              title={item.title}
              description={item.description}
              buttonText={buttonText}
              best={item.best}
              duration={item.duration}
              img={imgItems[key]}
              ctaOnClick={() => modalRef.current.openModal()}
            />
          )
        })}
      </ul>

      <ModalForm
        wrapperClassName={cx.modal}
        childFunc={modalRef}
        title={modalTitle}
        template={modalTemplate}
        type={modalType}
      >
        <FormSignUp submitLabel={modalSubmit} />
      </ModalForm>
    </section>
  )
}

Packages.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
  buttonText: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalSubmit: PropTypes.string,
  modalTemplate: PropTypes.string,
  modalType: PropTypes.string,
}

export default Packages
