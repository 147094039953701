import React, { useLayoutEffect, useState } from 'react'
import { debounce } from './debounce'

export default function useWindowPosition() {
  const [scrollPosition, setPosition] = useState(0)

  useLayoutEffect(() => {
    const updatePosition = debounce(() => {
      setPosition(window.pageYOffset)
    }, 50)

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}
