const _autop_newline_preservation_helper = (matches) => {
  return matches[0].replace('\n', '<WPPreserveNewline />')
}

export const wpautop = (context, className = '', br = true) => {
  if (typeof br === 'undefined') {
    br = true
  }

  var pre_tags = {}
  if (context.trim() === '') {
    return ''
  }

  // just to make things a little easier, pad the end
  context = context + '\n'

  if (context.indexOf('<pre') > -1) {
    var pee_parts = context.split('</pre>')
    var last_pee = pee_parts.pop()
    context = ''
    pee_parts.forEach(function (pee_part, index) {
      var start = pee_part.indexOf('<pre')

      // Malformed html?
      if (start === -1) {
        context += pee_part
        return
      }

      var name = '<pre wp-pre-tag-' + index + '></pre>'
      pre_tags[name] = pee_part.substr(start) + '</pre>'
      context += pee_part.substr(0, start) + name
    })

    context += last_pee
  }

  context = context.replace(/<br \/>\s*<br \/>/, '\n\n')

  // Space things out a little
  var allblocks =
    '(?:table|thead|tfoot|caption|col|colgroup|tbody|tr|td|th|div|dl|dd|dt|ul|ol|li|pre|form|map|area|blockquote|address|math|style|p|h[1-6]|hr|fieldset|legend|section|article|aside|hgroup|header|footer|nav|figure|figcaption|details|menu|summary)'
  context = context.replace(
    new RegExp('(<' + allblocks + '[^>]*>)', 'gmi'),
    '\n$1'
  )
  context = context.replace(
    new RegExp('(</' + allblocks + '>)', 'gmi'),
    '$1\n\n'
  )
  context = context.replace(/\r\n|\r/, '\n') // cross-platform newlines

  if (context.indexOf('<option') > -1) {
    // no P/BR around option
    context = context.replace(/\s*<option'/gim, '<option')
    context = context.replace(/<\/option>\s*/gim, '</option>')
  }

  if (context.indexOf('</object>') > -1) {
    // no P/BR around param and embed
    context = context.replace(/(<object[^>]*>)\s*/gim, '$1')
    context = context.replace(/\s*<\/object>/gim, '</object>')
    context = context.replace(/\s*(<\/?(?:param|embed)[^>]*>)\s*/gim, '$1')
  }

  if (context.indexOf('<source') > -1 || context.indexOf('<track') > -1) {
    // no P/BR around source and track
    context = context.replace(/([<\[](?:audio|video)[^>\]]*[>\]])\s*/gim, '$1')
    context = context.replace(/\s*([<\[]\/(?:audio|video)[>\]])/gim, '$1')
    context = context.replace(/\s*(<(?:source|track)[^>]*>)\s*/gim, '$1')
  }

  context = context.replace(/\n\n+/gim, '\n\n') // take care of duplicates

  // make paragraphs, including one at the end
  var pees = context.split(/\n\s*\n/)
  context = ''
  pees.forEach(function (tinkle) {
    context += '<p>' + tinkle.replace(/^\s+|\s+$/g, '') + '</p>\n'
  })

  context = context.replace(/<p>\s*<\/p>/gim, '') // under certain strange conditions it could create a P of entirely whitespace
  context = context.replace(
    /<p>([^<]+)<\/(div|address|form)>/gim,
    '<p>$1</p></$2>'
  )
  context = context.replace(
    new RegExp('<p>s*(</?' + allblocks + '[^>]*>)s*</p>', 'gmi'),
    '$1',
    context
  ) // don't context all over a tag
  context = context.replace(/<p>(<li.+?)<\/p>/gim, '$1') // problem with nested lists
  context = context.replace(/<p><blockquote([^>]*)>/gim, '<blockquote$1><p>')
  context = context.replace(/<\/blockquote><\/p>/gim, '</p></blockquote>')
  context = context.replace(
    new RegExp('<p>s*(</?' + allblocks + '[^>]*>)', 'gmi'),
    '$1'
  )
  context = context.replace(
    new RegExp('(</?' + allblocks + '[^>]*>)s*</p>', 'gmi'),
    '$1'
  )

  if (br) {
    context = context.replace(
      /<(script|style)(?:.|\n)*?<\/\\1>/gim,
      _autop_newline_preservation_helper
    ) // /s modifier from php PCRE regexp replaced with (?:.|\n)
    context = context.replace(/(<br \/>)?\s*\n/gim, '<br />\n') // optionally make line breaks
    context = context.replace('<WPPreserveNewline />', '\n')
  }

  context = context.replace(
    new RegExp('(</?' + allblocks + '[^>]*>)s*<br />', 'gmi'),
    '$1'
  )
  context = context.replace(
    /<br \/>(\s*<\/?(?:p|li|div|dl|dd|dt|th|pre|td|ul|ol)[^>]*>)/gim,
    '$1'
  )
  context = context.replace(/\n<\/p>$/gim, '</p>')

  if (Object.keys(pre_tags).length) {
    context = context.replace(
      new RegExp(Object.keys(pre_tags).join('|'), 'gi'),
      function (matched) {
        return pre_tags[matched]
      }
    )
  }

  // Add class
  if (className !== '') {
    context = context.replace(/<p>/gim, `<p class="${className}">`)
  }

  return context
}
