import React from 'react'

const SvgShare = ({ className = '' }) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      style={{ enableBackground: 'new 0 0 18 18' }}
      className={`fill-current ${className}`}
      fill="none"
    >
      <title>Share Inline</title>
      <path d="M13.125 1.875C11.8824 1.875 10.875 2.88236 10.875 4.125C10.875 4.32336 10.9007 4.51572 10.9489 4.69894C10.8957 4.70992 10.8433 4.72884 10.7931 4.75618L8.72576 5.88385L6.09593 7.38661C6.06905 7.40197 6.04391 7.41921 6.0206 7.43807C5.68504 7.23918 5.29336 7.125 4.875 7.125C3.63236 7.125 2.625 8.13236 2.625 9.375C2.625 10.6176 3.63236 11.625 4.875 11.625C5.53055 11.625 6.12062 11.3446 6.53186 10.8973L8.72681 12.1167L10.9426 13.3253C10.8985 13.5013 10.875 13.6854 10.875 13.875C10.875 15.1176 11.8824 16.125 13.125 16.125C14.3676 16.125 15.375 15.1176 15.375 13.875C15.375 12.6324 14.3676 11.625 13.125 11.625C12.4767 11.625 11.8924 11.8992 11.4818 12.338L9.27125 11.1322L7.06222 9.90499C7.10326 9.73503 7.125 9.55756 7.125 9.375C7.125 8.97023 7.01812 8.59042 6.83103 8.26227L9.27425 6.86614L11.3319 5.74381C11.3821 5.71638 11.4266 5.6824 11.4647 5.6435C11.876 6.09306 12.4676 6.375 13.125 6.375C14.3676 6.375 15.375 5.36764 15.375 4.125C15.375 2.88236 14.3676 1.875 13.125 1.875Z" />
    </svg>
  )
}

export default SvgShare
