import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import { Button } from '@components/global/buttons/Buttons'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import CheckItems from '@components/global/CheckItems'
import ElementImage from '@components/global/element/Image'
import SvgCalendar from '@svg/calendar.inline'
import * as cx from './Premier.module.scss'

const Premier = ({
  wrapperClassName,
  heading,
  subheading,
  title,
  description,
  best,
  duration,
  buttonText,
  features,
  img,
  label1 = 'Best for',
  label2 = 'Project duration:',
  label3 = 'More features',
  headingClassName = 'heading-5',
  subheadingClassName = 'subtitle-7',
  titleClassName = 'heading-3',
  labelClassName = 'subtitle-1',
  paragraphClassName = 'subtitle-5',
  featuresLabelClassName = 'small-heading strong',
  featuresClassName = 'default-body large',
  modalTitle = 'Sign Up Today For A <br>Free Consultation',
  modalSubmit = 'Get Started Today',
  modalTemplate = 'm1',
  modalType = 'b',
}) => {
  const modalRef = useRef()

  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={cx.container}>
        <div className={cx.header}>
          <h3>
            <span className={headingClassName}>{parse(heading)}</span>
            <span className={subheadingClassName}>{parse(subheading)}</span>
          </h3>
          <div className={cx.image}>
            <ElementImage src={img} alt={title} />
          </div>
        </div>

        <div className={cx.card}>
          <h4 className={titleClassName}>{title}</h4>

          <div className={cx.content}>
            <div className={cx.leftSide}>
              <p className={paragraphClassName}>{description}</p>
              <hr />
              <span className={labelClassName}>{label1}</span>
              <p className={paragraphClassName}>{best}</p>
              <span className={labelClassName}>{label2}</span>
              <div className={cx.duration}>
                <span>
                  <SvgCalendar />
                </span>
                <span className={paragraphClassName}>{duration}</span>
              </div>
            </div>
            <div className={cx.rightSide}>
              <span className={featuresLabelClassName}>{label3}</span>
              <CheckItems
                parentClass={cx.features}
                items={features}
                itemClass={featuresClassName}
              />
            </div>
          </div>

          <Button
            text={buttonText}
            onClick={() => modalRef.current.openModal()}
          />
        </div>
      </div>

      <ModalForm
        wrapperClassName={cx.modal}
        childFunc={modalRef}
        title={modalTitle}
        template={modalTemplate}
        type={modalType}
      >
        <FormSignUp submitLabel={modalSubmit} />
      </ModalForm>
    </section>
  )
}

Premier.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  best: PropTypes.string,
  duration: PropTypes.string,
  buttonText: PropTypes.string,
  features: PropTypes.array,
  img: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  featuresLabelClassName: PropTypes.string,
  featuresClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalSubmit: PropTypes.string,
  modalTemplate: PropTypes.string,
  modalType: PropTypes.string,
}

export default Premier
