import React from 'react'
import PropTypes from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import * as cx from './Banner.module.scss'

const Banner = ({
  wrapperClassName,
  breadcrumbs,
  bg,
  img,
  heading,
  subheading,
  buttonText,
  alt,
  headingClassName = 'heading-1',
  subheadingClassName = 'subtitle-5',
  buttonScrollTo,
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Breadcrumbs
        wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
        data={breadcrumbs}
      />

      <div className={cx.container}>
        <div className={cx.content}>
          <h2 className={cx.heading}>
            <span className={headingClassName}>{heading}</span>
            <span className={subheadingClassName}>{subheading}</span>
          </h2>
          <Button
            text={buttonText}
            svg={<SvgArrowAlt />}
            onClick={() => scrollTo(buttonScrollTo)}
          />
        </div>

        <div className={cx.image}>
          <ElementImage src={img} alt={alt} lazy={false} />
        </div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  bg: PropTypes.string,
  img: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  buttonText: PropTypes.string,
  buttonScrollTo: PropTypes.string,
  alt: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
}

export default Banner
