// extracted by mini-css-extract-plugin
export var card = "Premier-module--card--69246";
export var container = "Premier-module--container--01dfd";
export var content = "Premier-module--content--0f850";
export var duration = "Premier-module--duration--d4c54";
export var features = "Premier-module--features--e8219";
export var header = "Premier-module--header--990c4";
export var image = "Premier-module--image--49f7e";
export var leftSide = "Premier-module--left-side--e6151";
export var modal = "Premier-module--modal--91219";
export var rightSide = "Premier-module--right-side--9b996";
export var wrapper = "Premier-module--wrapper--337ee";