import React from 'react'

const SvgCalendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      className="stroke-current"
    >
      <g clipPath="url(#clip0_1256_21259)">
        <path
          d="M15.7267 28.3927H6.66667C5.95942 28.3927 5.28115 28.1117 4.78105 27.6116C4.28095 27.1115 4 26.4332 4 25.726V9.72599C4 9.01875 4.28095 8.34047 4.78105 7.84037C5.28115 7.34028 5.95942 7.05933 6.66667 7.05933H22.6667C23.3739 7.05933 24.0522 7.34028 24.5523 7.84037C25.0524 8.34047 25.3333 9.01875 25.3333 9.72599V15.0593"
          stroke="#A5A5AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 19.0593V24.3927H29.3333"
          stroke="#A5A5AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 29.726C26.9455 29.726 29.3334 27.3382 29.3334 24.3927C29.3334 21.4471 26.9455 19.0593 24 19.0593C21.0545 19.0593 18.6667 21.4471 18.6667 24.3927C18.6667 27.3382 21.0545 29.726 24 29.726Z"
          stroke="#A5A5AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 4.39258V9.72591"
          stroke="#A5A5AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33331 4.39258V9.72591"
          stroke="#A5A5AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 15.0593H25.3333"
          stroke="#A5A5AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1256_21259">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.392578)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgCalendar
