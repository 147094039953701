import { useStaticQuery, graphql } from 'gatsby'

const useSeoPackagesMetadata = () => {
  const query = useStaticQuery(graphql`
    query SeoPackagesMetadata {
      bannerImg: file(relativePath: { eq: "seo-packages/banner/img-2.png" }) {
        id
        ...SeoPackagesImageOptimize
      }
      bannerBG: file(relativePath: { eq: "seo-packages/banner/bg.jpg" }) {
        id
        ...SeoPackagesImageOptimize
      }
      bannerBGTablet: file(
        relativePath: { eq: "seo-packages/banner/bg-tablet.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      bannerBGPhone: file(
        relativePath: { eq: "seo-packages/banner/bg-phone.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      featuresImg: file(
        relativePath: { eq: "seo-packages/others/features-img.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }

      packagesFoundation: file(
        relativePath: { eq: "seo-packages/packages/foundation.png" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      packagesLocal: file(
        relativePath: { eq: "seo-packages/packages/local.png" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      packagesTotal: file(
        relativePath: { eq: "seo-packages/packages/total.png" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }

      compareBGDesktop: file(
        relativePath: { eq: "seo-packages/others/compare-bg-desktop.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      compareBG: file(
        relativePath: { eq: "seo-packages/others/compare-bg.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      premierImg: file(
        relativePath: { eq: "seo-packages/others/premier-img.png" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      faqsBGDesktop: file(
        relativePath: { eq: "seo-packages/others/faqs-bg-desktop.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      faqsBG: file(relativePath: { eq: "seo-packages/others/faqs-bg.jpg" }) {
        id
        ...SeoPackagesImageOptimize
      }
      reviewsBG: file(
        relativePath: { eq: "seo-packages/others/reviews-bg.jpg" }
      ) {
        id
        ...SeoPackagesImageOptimize
      }
      lcfBG: file(relativePath: { eq: "seo-packages/others/lcf-bg.jpg" }) {
        id
        ...SeoPackagesImageOptimize
      }
    }

    fragment SeoPackagesImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useSeoPackagesMetadata
