import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import * as cx from './Buttons.module.scss'

export const Button = React.forwardRef(
  (
    {
      wrapperClassName,
      text,
      textClassName,
      ghost,
      svg,
      small,
      large,
      submitType,
      onClick,
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        aria-label={parse(text)}
        className={clsx('primary-button', wrapperClassName, {
          small: small,
          large: large,
          alt: ghost,
        })}
        type={submitType ? 'submit' : 'button'}
        onClick={onClick}
      >
        <span className={textClassName}>{parse(text)}</span>
        {svg && <span>{svg}</span>}
      </button>
    )
  }
)

const Buttons = ({
  wrapperClassName,
  ctaRef,
  ghostRef,
  ctaText,
  ghostText,
  ctaClassName,
  ghostClassName,
  ctaTextClassName,
  ghostTextClassName,
  ctaSvg,
  ghostSvg,
  small,
  large,
  submitType,
  ctaOnClick,
  ghostOnClick,
}) => {
  return (
    <div className={clsx([cx.wrapper], wrapperClassName)}>
      <Button
        ref={ghostRef}
        ghost
        wrapperClassName={ghostClassName}
        text={ghostText}
        textClassName={ghostTextClassName}
        svg={ghostSvg}
        small={small}
        large={large}
        onClick={ghostOnClick}
      />
      <Button
        ref={ctaRef}
        wrapperClassName={ctaClassName}
        text={ctaText}
        textClassName={ctaTextClassName}
        svg={ctaSvg}
        small={small}
        large={large}
        submitType={submitType}
        onClick={ctaOnClick}
      />
    </div>
  )
}

Button.propTypes = {
  wrapperClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  ghost: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  submitType: PropTypes.bool,
  onClick: PropTypes.func,
}

Buttons.propTypes = {
  wrapperClassName: PropTypes.string,
  ctaText: PropTypes.string,
  ghostText: PropTypes.string,
  ctaClassName: PropTypes.string,
  ghostClassName: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  submitType: PropTypes.bool,
  ctaOnClick: PropTypes.func,
  ghostOnClick: PropTypes.func,
}

export default Buttons
