// extracted by mini-css-extract-plugin
export var accordion = "Faqs-module--accordion--79833";
export var background = "Faqs-module--background--e669f";
export var bgfade = "Faqs-module--bgfade--b523a";
export var bgfull = "Faqs-module--bgfull--d8fdb";
export var button = "Faqs-module--button--1a5e6";
export var container = "Faqs-module--container--b8d6d";
export var content = "Faqs-module--content--bfdab";
export var heading = "Faqs-module--heading--359ee";
export var lift = "Faqs-module--lift--50560";
export var lists = "Faqs-module--lists--fab01";
export var question = "Faqs-module--question--0603b";
export var wrapper = "Faqs-module--wrapper--e68e8";