import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Buttons from '@components/global/buttons/Buttons'
import useMedia, { media } from '@hooks/useMedia'
import useWindowPosition from '@utils/useWindowPosition'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import SvgShare from '@svg/share.inline'
import SvgFacebook from '@svg/iconFacebook.inline'
import SvgInstagram from '@svg/iconInstagram.inline'
import SvgLinkedIn from '@svg/iconLinkedIn.inline'
import SvgYouTube from '@svg/iconYouTube.inline'
import SvgPinterest from '@svg/iconPinterest.inline'
import * as cx from './FloatingShareButtons.module.scss'

const FloatingShareButtons = ({ height, title, excerpt, url }) => {
  const [show, setShow] = useState(false)
  const [pageHeight, setPageHeight] = useState(null)
  const isPhone = useMedia(media.phone)
  const isTablet = useMedia(media.tablet)
  const isDesktop = useMedia(media.desktop)

  const iconItems = [
    { icon: <SvgFacebook />, label: 'Facebook' },
    { icon: <SvgInstagram />, label: 'Instagram' },
    { icon: <SvgLinkedIn />, label: 'LinkedIn' },
    { icon: <SvgYouTube />, label: 'Youtube' },
    { icon: <SvgPinterest />, label: 'Pinterest' },
  ]

  useEffect(() => {
    const thirdOfPageHeight = ((30 / 100) * document.body.offsetHeight).toFixed(
      2
    )
    const mediaHeight = !height
      ? thirdOfPageHeight
      : isDesktop
      ? height?.desktop
      : isTablet
      ? height?.tablet
      : isPhone
      ? height?.phone
      : ''

    if (typeof document === 'undefined') return
    setPageHeight(mediaHeight)
  }, [isDesktop, isTablet, isPhone])

  return (
    <div
      className={clsx([cx.wrapper], {
        [cx.active]: useWindowPosition() > pageHeight,
      })}
    >
      <Buttons
        ghostText="Back to Top"
        ctaText="Share This"
        ghostTextClassName="overline-heading"
        ctaTextClassName="overline-heading"
        ghostSvg={<SvgArrowAlt />}
        ctaSvg={<SvgShare />}
        ghostOnClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }}
        ctaOnClick={() => {
          setShow(!show)
        }}
      />

      <ul
        className={clsx([cx.smi], {
          [cx.active]: show,
        })}
      >
        {iconItems?.map((item, key) => {
          return (
            <li key={key}>
              <a href="#" target="_blank" aria-label={item.label}>
                {item.icon}
                <span className="sr-only">{item.label}</span>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

FloatingShareButtons.propTypes = {
  height: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  url: PropTypes.string,
}

export default FloatingShareButtons
