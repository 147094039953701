import React, { useRef } from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import CheckItems from '@components/global/CheckItems'
import { Button } from '@components/global/buttons/Buttons'
import useWindowSize from '@hooks/useWindowSize'
import * as cx from './Compare.module.scss'

const CompareCard = ({
  title,
  duration,
  features,
  buttonText,
  titleClassName = 'subtitle-2',
  bodyClassName = 'default-body large',
  ctaOnClick,
}) => {
  return (
    <li className={cx.item}>
      <span className={titleClassName}>{title}</span>
      <span className={bodyClassName}>{parse(duration)}</span>
      <div className={cx.features}>
        <CheckItems items={features} itemClass={bodyClassName} />
      </div>
      <Button text={buttonText} onClick={ctaOnClick} />
    </li>
  )
}

const Compare = ({
  wrapperClassName,
  bg,
  heading,
  subheading,
  buttonText,
  items,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-7',
  modalTitle = 'Sign Up Today For A <br>Free Consultation',
  modalSubmit = 'Get Started Today',
  modalTemplate = 'm1',
  modalType = 'b',
}) => {
  const modalRef = useRef()
  const isPhone = useWindowSize().width <= 767

  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <h4 className={cx.header}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h4>
      <ul className={cx.list}>
        {items.map((item, key) => {
          return (
            <CompareCard
              key={key}
              title={item.title}
              duration={item.duration}
              features={
                isPhone && item.features.phone
                  ? item.features?.phone
                  : item.features.desktop
              }
              buttonText={buttonText}
              ctaOnClick={() => modalRef.current.openModal()}
            />
          )
        })}
      </ul>
      <ModalForm
        wrapperClassName={cx.modal}
        childFunc={modalRef}
        title={modalTitle}
        template={modalTemplate}
        type={modalType}
      >
        <FormSignUp submitLabel={modalSubmit} />
      </ModalForm>
    </section>
  )
}

Compare.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  buttonText: PropTypes.string,
  items: PropTypes.array,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalSubmit: PropTypes.string,
  modalTemplate: PropTypes.string,
  modalType: PropTypes.string,
}

export default Compare
