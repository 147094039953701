import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'
import parse from 'html-react-parser'

import * as cx from './Breadcrumbs.module.scss'

const Breadcrumbs = ({ wrapperClassName = '', data }) => {
  const isTwoItems = data?.length === 2

  return (
    <p
      className={clsx(cx.wrapper, wrapperClassName, isTwoItems && cx.twoItems)}
    >
      {data?.map((item, index) => {
        const isLastItem = data?.length === index + 1

        return (
          <span key={index} className={clsx(isLastItem && cx.lastitem)}>
            {/* {!isLastItem && (
              <> */}
            <Link to={item.url} aria-label={item.text}>
              {parse(item.text)}
            </Link>
            {/* </>
            )} */}
            {/* {isLastItem && <>{parse(item.text)}</>} */}
          </span>
        )
      })}
    </p>
  )
}

Breadcrumbs.propTypes = {
  wrapperClassName: PropTypes.string,
  data: PropTypes.array.isRequired,
}

export default Breadcrumbs
