import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import ToggleAccordion from '@components/global/accordions/ToggleAccordion'
import * as cx from './Faqs.module.scss'

const Faqs = ({
  wrapperClassName,
  bgImg,
  bgImgAlt = 'Frequently Asked Questions',
  heading = 'Frequently Asked Questions',
  headingClassName = 'subtitle-1',
  items,
  questionClassName = 'subtitle-3',
  answerClassName,
  noZoomEffect = false,
  liftContent = false,
  extendTitleSpace = false,
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <ElementImage
        className={clsx(
          `accordions-faqs-background`,
          cx.background,
          noZoomEffect ? cx.bgfade : cx.bgfull
        )}
        src={bgImg}
        alt={bgImgAlt}
      />

      <div className={clsx(cx.container, liftContent && cx.lift)}>
        <div className={clsx(cx.content, 'accordions-faqs-content')}>
          <h2 className={clsx(headingClassName, [cx.heading])}>{heading}</h2>
          {items?.map((item, index) => {
            return (
              <ToggleAccordion
                key={index}
                parentClass={cx.accordion}
                title={item.question}
                btnClass={clsx(questionClassName, [cx.button])}
                childClass={cx.question}
                extendTitleSpace={extendTitleSpace}
              >
                {item.answer?.map((text, key) => {
                  return (
                    <p key={key} className={answerClassName}>
                      {parse(text)}
                    </p>
                  )
                })}

                {item.featureAnswer?.map((content, key) => {
                  return (
                    <p key={key}>
                      {content.map((text, key) => {
                        return (
                          <span key={key}>
                            {text}
                            <br />
                          </span>
                        )
                      })}
                    </p>
                  )
                })}

                {item.features?.map((content, key) => {
                  return (
                    <div key={key}>
                      <p key={key + 10} className={'subtitle-6'}>
                        {content.title}
                      </p>
                      <p>&nbsp;</p>
                      <p key={key + 20}>{content.content}</p>
                    </div>
                  )
                })}

                {item.lists && (
                  <ul className={clsx(cx.lists)}>
                    {item.lists?.map((list, key) => {
                      return (
                        <li key={key}>
                          <p key={key} className={answerClassName}>
                            {parse(list)}
                          </p>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </ToggleAccordion>
            )
          })}
        </div>
      </div>
    </section>
  )
}

Faqs.propTypes = {
  bgImg: PropTypes.string,
  bgImgAlt: PropTypes.string,
  heading: PropTypes.string,
  headingClassName: PropTypes.string,
  items: PropTypes.array,
  questionClassName: PropTypes.string,
  answerClassName: PropTypes.string,
}

export default Faqs
